import React from "react";
import "./HeaderTop.css";

import img18 from "../Image/Frame 3681.png";
import logo2 from "../Image/logo2.png";
import unstop from "../Image/Unstop-Logo.png";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";


// import logo from "../Image/logo_f.svg";

export default function Header() {
  return (
    <>
      <div className="header-top">
        <div className="header-img-wrapper">
          <img src={img18} alt="" />
          {/* <img src={img19} alt="" className="ss-header-img2" />
          <img src={img16} alt="" className="ss-header-img3" />
          <img src={img23} alt="" className="ss-header-img4" />
          <img src={img14} alt="" className="ss-header-img5" />
          <img src={img17} alt="" className="ss-header-img6" />
          <img src={img15} alt="" className="ss-header-img7" />
          <img src={img21} alt="" className="ss-header-img8" />
          <img src={img22} alt="" className="ss-header-img9" /> */}
        </div>
        <div className="logo-heading">
          <div style={{ display: "flex" }}>
            <div className="logo-img">
              <img src={logo2} alt="logo2" className="th-header-logo" />
            </div>
            <div className="text">
              <p className="para1">National Social Summit</p>
              <p className="para2">22th - 23th Feb 2025</p>
            </div>
          </div>
          <br />
          <div id="para3"> <span style={{ marginRight: "9px" }}>Powered by</span>  <img style={{ height: "40px" }} src={unstop} alt="" /> </div>
          <br /><br />
          <div className="h_tickets"><NavLink to="/tickets">
            <button
              id="btn-1"
              style={{ height: "30px", display:"flex", justifyContent:"center", alignItems:"center", padding:"30px 60px", fontSize:"20px" }}
            >
             Buy Tickets
            </button>
          </NavLink></div>


        </div>
      </div>
    </>
  );
}
